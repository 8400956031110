import type { FC, PropsWithChildren } from 'react';
import { useCallback, useEffect, useMemo, useRef } from 'react';

import { AnalyticsContext } from './context';
import { useGetGlobalAttributes } from './global-attributes';
import type { AnalyticsEvent } from './types';

const UNKNOWN = 'unknown';

const CAPTURE = { capture: true };

const explainTarget = (currentTarget: Element | EventTarget | null) => {
	let actionElement = UNKNOWN;
	let actionTarget = '';
	let actionTargetId = UNKNOWN;
	const containers: string[] = [];

	while (currentTarget) {
		// extract target
		const { tagName, dataset } = currentTarget as any;

		switch (tagName) {
			case 'A': {
				actionElement = 'link';

				actionTarget =
					(currentTarget as HTMLAnchorElement).getAttribute('href') ||
					'';

				break;
			}

			case 'BUTTON': {
				actionElement = 'button';
				break;
			}

			default:
				break;
		}

		if (dataset) {
			if (dataset.actionName) {
				actionTargetId = dataset.actionName;
			}

			if (dataset.actionSection) {
				containers.push(
					`${dataset.actionSection}--${dataset.actionSectionId}`,
				);
			}
		}

		currentTarget = (currentTarget as any).parentNode as HTMLElement;
	}

	return {
		actionElement,
		actionTarget,
		actionTargetId,
		containers,
	};
};

export const AnalyticsEffector: FC<
	PropsWithChildren<{
		onEvent: (event: AnalyticsEvent) => void;
	}>
> = ({ children, onEvent }) => {
	const ref = useRef<HTMLDivElement>(null);
	const globalAttributes = useGetGlobalAttributes();

	useEffect(() => {
		const target = ref.current;

		if (!target) {
			return () => {
				//
			};
		}

		const handle = (e: MouseEvent) => {
			const currentTarget: HTMLElement = e.target as HTMLElement;
			const { actionElement, actionTarget, actionTargetId, containers } =
				explainTarget(currentTarget);

			onEvent({
				action: 'click',
				actionElement,
				actionTarget,
				actionTargetId,
				containers: containers.reverse(),
				...globalAttributes('ui'),
			});
		};

		target.addEventListener('click', handle, CAPTURE);

		return () => {
			target.removeEventListener('click', handle, CAPTURE);
		};
	}, []);

	const onLocalEvent = useCallback(
		(event: string, origin: Element | EventTarget | null) => {
			const { containers } = explainTarget(origin);

			onEvent({
				action: 'event',
				actionName: event,
				containers: containers.reverse(),
				...globalAttributes('event'),
			});
		},
		[],
	);

	const context = useMemo(() => ({ onEvent: onLocalEvent }), []);

	return (
		<div style={{ display: 'contents' }} ref={ref}>
			<AnalyticsContext connector={context}>{children}</AnalyticsContext>
		</div>
	);
};
