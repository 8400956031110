import './bootstrap-widget';

// ----

import {
	type ImpressionCooldown,
	type ImpressionSequence,
} from '@thanks/impression-type';
import { getCookie } from '@theway/cookies/browser';

import { analytics } from './analytics';
import { getAppRenderer } from './app/ViewSelector';
import { initTunnel } from './bridge';
import { getPopupRenderer } from './popup/render';
import { SHOULD_LOG } from './utils/logging';
import { verifyWidgetCooldown } from './utils/cooldown';

const WIDGET_INIT_MARKER = 'widget init';

declare global {
	interface Window {
		awaitPreload?: boolean;
		$impression: ImpressionSequence;
	}
}

const preload = async (impression: ImpressionSequence, step: number) => {
	const tm0 = Date.now();

	try {
		await getAppRenderer(impression.widget).preload(step, impression);
	} catch (e) {
		// ignore all error. Preload does not care
	} finally {
		const preloadTime = Date.now() - tm0;
		analytics.phaseMetric('preload', { preloadTime });
		SHOULD_LOG && console.log('preload took', preloadTime);
	}
};

const checkInCooldownPeriod = (
	impression: ImpressionSequence | ImpressionCooldown,
) => {
	if (
		impression?.type === 'impressioncooldown' &&
		impression.isInPopupCooldown === true
	) {
		return true;
	}

	return;
};

if (window.awaitPreload) {
	// nothing
} else {
	const impression: ImpressionSequence = window['$impression']; // TODO connect this type to backend

	if (!impression) {
		throw new Error('no impression record');
	}

	const shouldRender = !checkInCooldownPeriod(impression);

	// kick preload early
	const preloadPromise = shouldRender
		? preload(impression, 0)
		: Promise.resolve();

	initTunnel(window.parent, async (tunnel, payload, channel, message) => {
		if (!shouldRender) {
			analytics.phaseMetric('skipped', { reason: 'cooldown' });
			channel.send('skipped', { reason: 'cooldown', impression });

			return;
		}

		SHOULD_LOG && console.timeEnd(WIDGET_INIT_MARKER);

		// check tracker cookies
		if (!getCookie('th-anonymousId') && impression.sessionId) {
			// console.log('no th-cookie found');
			if (payload.allowLocalStorage) {
				// console.log('will user local id');
				// relay sessionId to parent
				channel.send('set-local-id', impression.sessionId);
			} else {
				// console.log('local storage is not allowed');
				if (impression.analytics?.smartWidgetWindowEnd) {
					if (
						!verifyWidgetCooldown(
							impression.partnerSiteId,
							impression.analytics.smartWidgetWindowEnd,
						)
					) {
						// console.log('client-driven timeout');
						return 'ok: client-cooldown';
					}
					// console.log('client-driven cooldown end');
				} else {
					// console.log('no timeout set', impression.analytics);
				}
			}
		} else {
			// console.log('th-cookie found');
		}

		const errorCode = await getPopupRenderer(
			impression,
			preloadPromise,
		).render({ tunnel, payload, message });

		if (errorCode) {
			return errorCode;
		}

		analytics.phaseMetric('ready');
		channel.send('ready', { impression });

		return;
	});

	SHOULD_LOG && console.time(WIDGET_INIT_MARKER);
	analytics.phaseMetric('init');
}
