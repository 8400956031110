import { setFeatureFlag } from '@smile/experimentation';
import {
	type ImpressionSequence,
	ImpressionWidget,
} from '@thanks/impression-type';
import { BREAKPOINTS } from '@theway/helpers-css/breakpoints';
import {
	$object,
	$number,
	$enum,
	$opt,
	$string,
	lizodParse,
} from '@theway/lizod';

import { analytics } from '../analytics';
import type { ViewOptions } from '../app/App';
import { renderApp } from '../app/App';
import { type InitPayload, type TUNNEL_TYPE } from '../bridge';
import { actOnExperiment } from '../experimentation';
import { ensureWidget } from '../services/ensure-widget';
import { installClickInterceptor } from '../utils/click-interceptor';
import { installFocusWatcher } from '../utils/focus-watcher';
import { SHOULD_LOG } from '../utils/logging';
import { timeout } from '../utils/time';

import { configureAnalytics } from './analytics';

const uiPayload = $object(
	{
		topOffset: $opt($number),
		splashMode: $opt($string),
		mode: $enum(['desktop', 'mobile'] as const),
	},
	false,
);

const parseViewOptions = (message: unknown): ViewOptions => {
	const payload = lizodParse(message, uiPayload);

	return {
		offsetTop: payload.topOffset || undefined,
		// ignore info from the parent page
		mode: window.innerWidth >= BREAKPOINTS.sm ? 'desktop' : 'mobile',
		initialStep: payload.splashMode === 'skip' ? 1 : 0,
	};
};

export const getPopupRenderer = (
	impression: ImpressionSequence,
	preloadPromise: Promise<void>,
) => ({
	render: async ({
		tunnel,
		payload,
		message,
	}: {
		tunnel: TUNNEL_TYPE;
		payload: InitPayload;
		message: unknown;
	}) => {
		if (window.innerWidth < 400 && (message as any).flags?.scroll) {
			impression.widget = ImpressionWidget.PLATYCERCUS;
		}

		const { theme: themeOverride, ...flags } =
			payload?.flags?.switches || {};

		// activate stripe checkout form by default
		setFeatureFlag('stripeCheckout', true);

		// set feature flags
		Object.entries(flags).forEach(([name, value]) => {
			setFeatureFlag(name as any, Boolean(value));
		});

		const { experiment, theme } = impression;
		const activeTheme = String(themeOverride || theme);

		document.body.setAttribute('data-theme', activeTheme);

		const viewOptions = parseViewOptions(message);

		configureAnalytics({
			impression,
			referral: payload.referral,
			viewMode: viewOptions.mode,
			taint: payload.taint || 'default',
			theme: activeTheme || 'default',
		});

		impression.statusText = payload.statusText;

		actOnExperiment(experiment as any);

		SHOULD_LOG &&
			console.log({ on: impression.experimentation, experiment });

		const app = await renderApp(impression, {
			...viewOptions,
			...(impression.brand.type === 'skip' ? { initialStep: 1 } : {}),
		});

		const viewOptionsOverride: Partial<ViewOptions> = {};

		tunnel.on('resize', (message) => {
			app.setViewOptions({
				...parseViewOptions(message),
				...viewOptionsOverride,
			});
		});

		tunnel.on('close', async () => {
			analytics.phaseMetric('closed-by-host');
			tunnel.send('close');
			await analytics.flush();
			tunnel.send('terminate');
		});

		const SHOW_DELAY = 0;

		const destinationLock = timeout(
			Math.max(0, SHOW_DELAY - performance.now()),
		);
		const preloadLock = Promise.race([preloadPromise, timeout(500)]);

		await Promise.all([
			///
			destinationLock,
			preloadLock,
		]);

		const issueText = ensureWidget(app, viewOptions, viewOptionsOverride);

		if (issueText) {
			return issueText;
		}

		installFocusWatcher();

		if (window.__INTEGRATION_MODE__ === 'react-native') {
			installClickInterceptor((href) => {
				tunnel.send('click', href);
			});
		}

		return;
	},
});
